import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useMediaQuery } from "@mui/material";

// Define the Batch type
type Batch = {
  _id: string;
  name: string;
  active: boolean;
  isMain: boolean;
};

// BatchTable component
const BatchTable: React.FC<{ batches: Batch[]; isFetchingProcess: boolean }> = ({
  batches,
  isFetchingProcess,
}) => {
  const isDesktop = useMediaQuery("(min-width:600px)");
  return (
    <>
      {!isFetchingProcess ? (
        <TableContainer
          sx={{
            overflowX: isDesktop ? "unset" : "scroll",
            "&::-webkit-scrollbar": {
              height: "12px", // Adjust scrollbar height
              width: "12px", // Adjust scrollbar width
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888", // Adjust scrollbar thumb color
              borderRadius: "6px", // Adjust scrollbar thumb border radius
            },
          }}
        >
          <Table sx={{ minWidth: "max-content" }}>
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    color: "#000000",
                    backgroundColor: "#E0E0E0",
                    borderBottom: "1px solid #000000",
                  },
                }}
              >
                <TableCell style={{ fontWeight: 800 }}>Name</TableCell>
                <TableCell style={{ fontWeight: 800 }}>Estado</TableCell>
                <TableCell style={{ fontWeight: 800 }}>Info</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {batches.map(batch => (
                <TableRow key={batch._id}>
                  <TableCell>{batch.name}</TableCell>
                  <TableCell>{batch.active ? "Activo" : "Inactivo"}</TableCell>
                  <TableCell>{batch.isMain ? "Actual" : "N/A"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div>Obteniendo informacion...</div>
      )}
    </>
  );
};

export default BatchTable;
