import { styled } from "@mui/material";

export const ProcessLayout = styled("div")(({ theme }) => ({
    width: "100vw",
    height: "100vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}));

export const ProcessBox = styled("div")(({ theme }) => ({
    width: '90%',
    minWidth: '155px',
    maxWidth: '280px',
    maxHeight: '90vh',
    overflowY: 'auto',
    borderRadius: '15px',
    boxShadow: '0 8px 24px 0 #00000017!important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}));