import React, { useEffect, useState } from 'react'
import * as Styled from './styles'
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const Process = () => {

    const { id } = useParams();

    const [product, setProduct] = useState<any>([])

    useEffect(() => {
        const init = async () => {
            try {
                const data = await fetch(`https://ipfs.io/ipfs/${id}`)
                const json = await data.json()
                console.log(json)
                setProduct(json)
            } catch (e) {
                console.log("Errored on: ", e)
                toast.error("Ocurrion un error obteniendo la informacion.")
            }
        }
        init()
    }, [])

    return (
        <Styled.ProcessLayout>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '.5em'}}>
                <img src='/images/card-logo.png' />
            </div>
            <Styled.ProcessBox>
                <div style={{
                    backgroundImage: 'url(https://d1op7u6gs2lx31.cloudfront.net/300x300/producers/mhlambrese%40hotmail.com/products/images/af412833-b25a3c9f-af41.jpeg)',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    width: 100,
                    height: 100,
                    borderRadius: '40%',
                    border: '1px solid green',
                    marginTop: '2em',
                    marginBottom: '1em'
                }} />
                <div style={{
                    width: '90%',
                    fontSize: '1.4em',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    marginBottom: '.8em'
                }}>
                    <span>{product?.product?.product_name}</span>
                </div>
                <div style={{
                    width: '90%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '.8em'
                }}>
                    <span style={{
                        backgroundColor: '#F6F7FB',
                        padding: '1em',
                        borderRadius: '10px',
                        fontWeight: 800
                    }}>
                        {product?.product?.step_category ?? "Sin Categorizar"}
                    </span>
                </div>
                <div style={{
                    width: '90%',
                    textAlign: 'center',
                    fontSize: '1.2em',
                    marginBottom: '.5em'
                }}>
                    {product?.step?.step_name}
                </div>
                <div style={{
                    width: '90%',
                    fontSize: '1.1em',
                    textAlign: 'center',
                    marginBottom: '.7em'
                }}>
                    {product?.step?.step_description}
                </div>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '1em'
                }}>
                    <PersonIcon />
                    {product?.step?.step_responsible}
                </div>
                <div style={{
                    marginBottom: '2em'
                }}>
                    <Button>
                        Mas informacion
                    </Button>
                </div>
            </Styled.ProcessBox>
        </Styled.ProcessLayout>
    )
}

export default Process