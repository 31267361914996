// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDvByBca53nbv3yl2frICqFE6ym0Le5fxk",
  authDomain: "agrojusto-64abd.firebaseapp.com",
  projectId: "agrojusto-64abd",
  storageBucket: "agrojusto-64abd.appspot.com",
  messagingSenderId: "879844783293",
  appId: "1:879844783293:web:a317c5a15932e9e64773d4",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
