import { styled } from "@mui/material/styles";
import { Avatar, Button } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";

export const OnboardUserLayout = styled("div")(({ theme }) => ({
  height: "100vh",
  position: "relative",
  backgroundSize: "cover",
  backgroundImage: "url(/images/onboarding/verduras3-min.jpg)",
}));

export const OnboardPasswordBox = styled("div")(({ theme }) => ({
  margin: "0",
  backgroundColor: "white",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "2em 2em 2em 2em",
  borderRadius: "4px",
  width: "30%",
  maxWidth: "288px",
  minWidth: "250px",
}));

export const WelcomeText = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  color: "#0006",
  display: "flex",
  justifyContent: "center",
}));

export const DescriptionText = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  alignItems: "center",
}));

export const LoginButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#689f38",
  color: "white",
  textTransform: "none",
  width: "80%",
  fontSize: "1em",
  minHeight: "41px",
  "&:hover": {
    backgroundColor: "#689f38",
    color: "white",
  },
}));

export const Dot = styled(SensorsIcon)({
  // width: 10,
  // height: 10,
  borderRadius: "50%",
  color: "green",
  opacity: 0.7,
  position: "relative",
  animation: "glowing 2s infinite",
  animationName: "glowing",
  "@keyframes glowing": {
    "0%, 100%": {
      color: "green",
    },
    "50%": {
      color: "white",
    },
  },
});
