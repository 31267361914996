import React, { ChangeEvent, useState, useEffect } from "react";
import AWS from "aws-sdk";
import { useParams, useNavigate } from "react-router-dom";
import useDataStore from "../../store";
import { useAuth } from "../../providers/auth";
import { recoverPersonalSignature } from "@metamask/eth-sig-util";
import { toast } from "react-hot-toast";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { db } from "../../firebase";
import {
  doc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import * as Styled from "./styles";
import NavBar from "../../components/NavBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@mui/material";
import NewProcessDialog from "../../components/NewProcessDialog";
import NewBatchDialog from "../../components/NewBatchDialog";
import BatchTable from "../../components/BatchsTable/index";
import ArrowSvg from "../../assets/Arrow.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMediaQuery } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, backgroundColor: "#F6F7FB" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const projectId = process.env.REACT_APP_PROJECT_ID;
const projectSecret = process.env.REACT_APP_PROJECT_SECRET;
const authorization = "Basic " + btoa(projectId + ":" + projectSecret);

const ipfs = ipfsHttpClient({
  url: "https://ipfs.infura.io:5001/api/v0",
  headers: {
    authorization,
  },
});

const ViewProduct = () => {
  const isDesktop = useMediaQuery("(min-width:600px)");

  const { productId } = useParams();
  const { userToken, userData, account } = useDataStore();
  const { web3 } = useAuth();
  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [newBatch, setNewBatch] = useState(false);

  const [isFetching, setIsFetching] = useState<boolean>(true);

  const [product, setProduct] = useState<any>([]);
  const [process, setProcess] = useState<any[]>([]);
  const [batches, setBatches] = useState<any[]>([]);

  const [isFetchingProcess, setIsFetchingProcess] = useState<boolean>(true);

  const [newBatchName, setNewBatchName] = useState<string>("");

  const [stepCategory, setStepCategory] = useState<string>("");
  const [stepBatch, setStepBatch] = useState<string>("");
  const [stepName, setStepName] = useState<string>("");
  //  date and time
  const [selectedDateTime, setSelectedDateTime] = useState(getFormattedDateTime());

  // Function to get the current date and time in the required format
  function getFormattedDateTime() {
    const currentDate = new Date();
    const options = { timeZone: "America/Argentina/Buenos_Aires" };
    const formattedDate = currentDate.toLocaleString("en-US", {
      ...options,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return formattedDate.replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+)/, "$3-$1-$2T$4:$5");
  }

  const [stepDescription, setStepDescription] = useState<string>("");
  const [stepResponsible, setStepResponsible] = useState<string>("");
  const [stepLocation, setStepLocation] = useState<string>("");
  const [imageS3Locations, setImageS3Location] = useState<string>("");

  const [signed, setSigned] = useState<string>("");

  const [signing, setSigning] = useState<boolean>(false);

  const [value, setValue] = useState(0);

  const defaultMarkerPosition = {
    lat: -32.88427734257276,
    lng: -68.89183466305856,
  };
  const [markerPosition, setMarkerPosition] = useState(defaultMarkerPosition);

  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const handleMarkerDrag = (lat: number, lng: number) => {
    setMarkerPosition({ lat, lng });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Handler function to update state when date and time are changed
  const handleDateTimeChange = (event: any) => {
    setSelectedDateTime(event.target.value);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      // Read the selected image file and convert it to a data URL
      setFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result as string;
        setImagePreview(dataURL);
      };
      reader.readAsDataURL(file);
    } else {
      setFileName(null);
      setImagePreview(null);
    }
  };

  //UPLOAD IMAGE TO AGROJUSTO
  const handleImageUpload = async () => {
    if (imagePreview) {
      try {
        // AWS S3 configuration
        AWS.config.update({
          //AWS_ACCESS
          accessKeyId: "AKIA3WEHBEI4S2C6NWGI",
          //AWS_ACCESS_KEY_SECRET
          secretAccessKey: "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",
          region: "sa-east-1",
        });

        const s3 = new AWS.S3();
        // dev S3 BUCKET
        //const bucketName = "dev.images.agrojusto.com.ar";
        // prod: S3 BUCKET
        const bucketName = "prod.images.agrojusto.com.ar";
        const key = `blockchain/users/${userData.user.email}/processes/${fileName}.png`;
        // Convert data URL to buffer
        const imageBuffer = Buffer.from(
          imagePreview.replace(/^data:image\/\w+;base64,/, ""),
          "base64",
        );

        // Upload image to S3 bucket
        const uploadResult = await s3
          .upload({
            Bucket: bucketName,
            Key: key,
            Body: imageBuffer,
            ContentType: "image/png", // Adjust the content type based on your image type
          })
          .promise();

        //console.log("S3 Upload Response:", uploadResult);
        setImageS3Location(uploadResult.Location);
        return uploadResult.Location;
        // Additional logic after successful upload
      } catch (error: any) {
        console.log("Error uploading image:", error.message);
        toast.error("Error al guardar imagen. Reintenta nuevamente.");
      }
    }
  };

  useEffect(() => {
    console.log(account);
    console.log("UDATA: ", userData);
    initData();
  }, []);

  const initData = async () => {
    setIsFetching(true);
    await fetchProduct();
    await fetchProductProcesses();
    // await fetchAllProducts();
    setIsFetching(false);
  };

  const fetchAllProducts = async () => {
    const productsQuery = query(
      collection(db, "records"),
      // orderBy("created", "asc")
    );
    const queryRes = await getDocs(productsQuery);
    const data = queryRes.docs.map(doc => ({ ...doc.data(), id: doc.id }));

    console.log("ALL PRODUCTS > ", data);
  };

  const fetchProduct = async () => {
    console.log("Fetching...");
    const res = await fetch(`https://api.agrojusto.com/api/products/${productId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const json = await res.json();

    const locationLat =
      json.organization?.location?.lat !== null && json.organization?.location?.lat !== undefined
        ? json.organization.location.lat
        : defaultMarkerPosition.lat;

    const locationLng =
      json.organization?.location?.lng !== null && json.organization?.location?.lng !== undefined
        ? json.organization.location.lng
        : defaultMarkerPosition.lng;
    setMarkerPosition({ lat: locationLat, lng: locationLng });

    setProduct(json);
  };

  const fetchProductProcesses = async () => {
    console.log(productId);
    const productsQuery = query(
      collection(db, "records"),
      where("product_id", "==", productId),
      // orderBy("created", "asc")
    );
    const queryRes = await getDocs(productsQuery);
    const data: any = queryRes.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));

    const sortedData = data.sort(function (x: any, y: any) {
      return x.created - y.created;
    });

    const batches = sortedData
      .filter((item: any, index: number) => {
        return item.signed_data.batch;
      })
      .map((item: any) => {
        return item.signed_data.batch;
      });

    const sortedBatches = batches.filter(function (item: any, pos: number) {
      return batches.indexOf(item) == pos;
    });

    console.log("BATCHES: ", sortedBatches);
    setBatches(sortedBatches);

    setProcess(sortedData);
    setIsFetchingProcess(false);
  };

  const signAndVerify = async (fullMessage: string) => {
    setSigning(true);

    const signedMessage = await web3.eth.personal.sign(fullMessage, account, "");

    console.log("signedMessage:", signedMessage);
    setSigned(signedMessage);
    // recover the public address of the signer to verify
    const recoveredAddress = recoverPersonalSignature({
      data: fullMessage,
      signature: signedMessage,
    });

    console.log("Recovered address: ", recoveredAddress);

    console.log(
      recoveredAddress.toLocaleLowerCase() === account?.toLocaleLowerCase()
        ? "Signing success!"
        : "Signing failed!",
    );
    setSigning(false);
    // toast.success("Mensaje firmado y encriptado!");
    return signedMessage;
  };
  //note: upload
  const handleUploadToIpfs = async () => {
    const imageS3Location = await handleImageUpload();

    const myJson = {
      title: "Proceso",
      product: {
        product_name: product?.title,
        product_id: product?._id,
        product_description: product?.description,
      },
      batch: stepBatch,
      step: {
        step_category: stepCategory,
        step_name: stepName,
        step_description: stepDescription,
        step_responsible: stepResponsible,
        step_location: product?.producer?.profile?.address?.province || "Mendoza",
        step_date_time: selectedDateTime,
        step_coordinates: markerPosition,
        step_image: imageS3Location ? imageS3Location : "",
      },
    };

    const signature = await signAndVerify(JSON.stringify(myJson));

    console.log("SIGNATURE: ", signature);

    try {
      const imageS3Location = await handleImageUpload();
      const result = await ipfs.add(JSON.stringify(myJson));
      console.log(
        "Uploaded to IPFS: ",
        `https://agrojusto-testnet.infura-ipfs.io/ipfs/${result.path}`,
      );

      const docRef = await addDoc(collection(db, "records"), {
        product_id: product?._id,
        signed_data: myJson,
        ipfs_link: `https://ipfs.io/ipfs/${result.path}`,
        user_data: userData,
        signature: signature,
        created: serverTimestamp(),
        step_date_time: selectedDateTime,
        location: markerPosition,
        s3Image: imageS3Location ? imageS3Location : "",
      });

      console.log("Uploaded to DB: ", docRef.id);

      // toast.success("Proceso almacenado en blockchain!");

      await initData();
      setOpen(false);
      setStepName("");
      setStepDescription("");
      setStepResponsible("");
      setStepBatch("");
      setImagePreview(null);
      setImageS3Location("");
      setMarkerPosition({
        lat: -32.88427734257276,
        lng: -68.89183466305856,
      });
    } catch (e) {
      console.log("error", e);
      // toast.error("Error");
    }
  };

  const handleDeleteProcess = async (item: any) => {
    console.log("deleting...", item);
    try {
      const deleted = await deleteDoc(doc(db, "records", item.id));
      console.log("deleted", deleted);
      await initData();
    } catch (e) {
      console.log(e);
    }
  };

  const handleUploadNewBatch = async (newProcess: any) => {
    const myJson = {
      title: "Proceso",
      product: {
        product_name: newProcess?.signed_data.product?.product_name,
        product_id: newProcess?.signed_data?.product?.product_id,
        product_description: newProcess?.signed_data?.product?.product_description,
      },
      batch: newBatchName,
      step: {
        step_category: newProcess?.signed_data?.step?.step_category,
        step_name: newProcess?.signed_data?.step?.step_name,
        step_description: newProcess?.signed_data?.step?.step_description,
        step_responsible: newProcess?.signed_data?.step?.step_responsible,
        step_location: newProcess?.signed_data?.step?.step_location,
      },
    };

    console.log("LAST STEP: ", myJson);

    const signature = await signAndVerify(JSON.stringify(myJson));

    try {
      const result = await ipfs.add(JSON.stringify(myJson));
      console.log(
        "Uploaded to IPFS: ",
        `https://agrojusto-testnet.infura-ipfs.io/ipfs/${result.path}`,
      );

      const docRef = await addDoc(collection(db, "records"), {
        product_id: product?._id,
        signed_data: myJson,
        ipfs_link: `https://ipfs.io/ipfs/${result.path}`,
        user_data: userData,
        signature: signature,
        created: serverTimestamp(),
      });

      console.log("Uploaded to DB: ", docRef.id);

      console.log("CONTINUE WITHG OTHER??");
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleCreateNewBatch = async (batch: string) => {
    console.log("Creating...");
    console.log("Fetching batch...");
    const batchProcess = process.filter((item: any) => item.signed_data.batch === batch);

    try {
      const createNewBatch = await Promise.all(
        batchProcess.map(async (item: any) => {
          await handleUploadNewBatch(item);
        }),
      );
      toast.success("Nuevo lote generado con éxito");
      await initData();
    } catch (e) {
      console.log("ERROR: ", e);
      toast.error("Error al crear lote. Reintenta nuevamente.");
    }
  };

  return (
    <>
      <NavBar />
      <Styled.ViewProductLayout>
        <Styled.Anchor onClick={() => navigate("/")}>
          <img src={ArrowSvg} alt="Arrow" style={{ verticalAlign: "middle" }} />
          Volver a mis productos
        </Styled.Anchor>

        <Accordion
          sx={{ bgcolor: "#F6F7FB", boxShadow: "none", borderBottom: "1px solid black" }}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#0029FF", fontSize: "2rem" }} />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ p: "0" }}
          >
            <Styled.Title>Detalle del Producto</Styled.Title>
          </AccordionSummary>
          <AccordionDetails sx={{ px: "0" }}>
            <b>ID:</b> {product?._id}
            <br />
            <br />
            <b>Nombre:</b> {product?.title}
            <br />
            <br />
            <b>Descripción:</b>
            <br /> • {product?.description}
            <br />
            <br />
            <b>Categoría:</b> {product?.productType?.name}
          </AccordionDetails>
        </Accordion>

        {/* TABS */}

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#4CB766",
                },
                "& .Mui-selected": {
                  color: "#000000!important",
                },
              }}
            >
              <Tab label="Procesos: (info on-chain)" {...a11yProps(0)} sx={{ px: "0" }} />
              <Tab label="Lotes" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Styled.Anchor onClick={() => setOpen(true)} sx={{ fontWeight: "500", mb: 2 }}>
              + Nuevo Proceso
            </Styled.Anchor>

            {!isFetchingProcess ? (
              <>
                <TableContainer
                  sx={{
                    overflowX: isDesktop ? "unset" : "scroll",
                    "&::-webkit-scrollbar": {
                      height: "12px", // Adjust scrollbar height
                      width: "12px", // Adjust scrollbar width
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888", // Adjust scrollbar thumb color
                      borderRadius: "6px", // Adjust scrollbar thumb border radius
                    },
                  }}
                >
                  <Table aria-label="simple table" sx={{ minWidth: "max-content" }}>
                    <TableHead>
                      <TableRow
                        sx={{
                          "& th": {
                            color: "#000000",
                            backgroundColor: "#E0E0E0",
                            borderBottom: "1px solid #000000",
                          },
                        }}
                      >
                        <TableCell style={{ fontWeight: 800 }}>Categoría</TableCell>
                        <TableCell style={{ fontWeight: 800 }}>Lote</TableCell>
                        <TableCell style={{ fontWeight: 800 }}>Fecha y Horario</TableCell>
                        <TableCell style={{ fontWeight: 800 }}>Nombre</TableCell>
                        <TableCell style={{ fontWeight: 800 }}>Responsable</TableCell>
                        <TableCell style={{ fontWeight: 800 }}>Link</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {process.map(row => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.signed_data.step.step_category ?? "N/A"}
                          </TableCell>
                          <TableCell>{row.signed_data.batch ?? "N/A"}</TableCell>
                          <TableCell>
                            {row.signed_data.step?.step_date_time
                              ? row.signed_data.step.step_date_time.replace("T", " ")
                              : "N/A"}
                          </TableCell>
                          <TableCell>{row.signed_data.step.step_name}</TableCell>
                          <TableCell>{row.signed_data.step.step_responsible}</TableCell>
                          <TableCell>
                            <a
                              href={row.ipfs_link.replace(
                                "https://ipfs.io/ipfs/",
                                "https://agrojusto-testnet.infura-ipfs.io/ipfs/",
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Link
                            </a>
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              toast.promise(handleDeleteProcess(row), {
                                loading: "Eliminando proceso...",
                                success: <b>Proceso eliminado!</b>,
                                error: <b>Error al eliminar proceso.</b>,
                              })
                            }
                          >
                            <DeleteIcon
                              sx={{ color: "#0029FF", fontSize: "1.5rem", cursor: "pointer" }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <div>Obteniendo informacion...</div>
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {/* <Styled.Anchor onClick={() => setNewBatch(true)} sx={{ fontWeight: "500", mb: 2 }}>
              + Nuevo Lote
            </Styled.Anchor> */}
            {product.batchs?.length > 0 && (
              <BatchTable batches={product.batchs} isFetchingProcess={isFetchingProcess} />
            )}
          </CustomTabPanel>
        </Box>

        {/* TABS */}
      </Styled.ViewProductLayout>

      <NewProcessDialog
        open={open}
        setOpen={setOpen}
        stepCategory={stepCategory}
        setStepCategory={setStepCategory}
        stepName={stepName}
        setStepName={setStepName}
        stepBatch={stepBatch}
        setStepBatch={setStepBatch}
        stepDescription={stepDescription}
        setStepDescription={setStepDescription}
        stepResponsible={stepResponsible}
        setStepResponsible={setStepResponsible}
        markerPosition={markerPosition}
        handleMarkerDrag={handleMarkerDrag}
        imagePreview={imagePreview}
        handleImageChange={handleImageChange}
        batches={product.batchs}
        selectedDateTime={selectedDateTime}
        handleDateTimeChange={handleDateTimeChange}
        handleUploadToIpfs={() =>
          toast.promise(handleUploadToIpfs(), {
            loading: "Firmando informacion...",
            success: <b>Proceso guardado!</b>,
            error: <b>Error al guardar proceso.</b>,
          })
        }
      />

      <NewBatchDialog
        open={newBatch}
        setOpen={setNewBatch}
        handleCreateNewBatch={handleCreateNewBatch}
        process={process}
        batches={batches}
        newBatchName={newBatchName}
        setNewBatchName={setNewBatchName}
      />
    </>
  );
};

export default ViewProduct;
