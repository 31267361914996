import React, { useState, useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import MapComponent from "../GoogleMaps/MapComponent";
import toast from "react-hot-toast";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Box,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
//import * as Styled from "./styles";
import { useMediaQuery } from "@mui/material";

export default function NewProcessDialog({
  open,
  setOpen,
  stepCategory,
  setStepCategory,
  stepName,
  setStepName,
  stepBatch,
  setStepBatch,
  stepDescription,
  setStepDescription,
  stepResponsible,
  setStepResponsible,
  markerPosition,
  handleMarkerDrag,
  imagePreview,
  handleImageChange,
  handleUploadToIpfs,
  handleSaveNewProcess,
  batches,
  selectedDateTime,
  handleDateTimeChange,
}: any) {
  const isDesktop = useMediaQuery("(min-width:600px)");

  const render = (status: Status) => <h1 style={{ textAlign: "center" }}>Cargando</h1>;

  const steps = ["Datos generales", "Ubicación", "Imagen"];

  const [activeStep, setActiveStep] = React.useState(0);

  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return step === 2;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (
      activeStep === 0 &&
      (stepCategory === "" ||
        stepName === "" ||
        stepBatch === "" ||
        stepDescription === "" ||
        stepResponsible === "")
    ) {
      toast("Por favor, completa todos los campos.", {
        icon: "ℹ️",
      });
      return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleUploadToIpfs();
    setOpen(false);
    handleReset();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box
            sx={{
              width: isDesktop ? "25vw" : "100%",
            }}
          >
            {activeStep === steps.length ? (
              <React.Fragment>
                <DialogTitle align="center">Desea guardar los cambios?</DialogTitle>

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button color="error" onClick={handleReset}>
                    No
                  </Button>
                  <Button color="success" onClick={handleConfirm}>
                    Guardar
                  </Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 0 && (
                  <>
                    <DialogTitle align="center">
                      Datos generales
                      <DialogContentText variant="caption">(1/3)</DialogContentText>
                    </DialogTitle>
                    <FormControl fullWidth variant="standard">
                      <InputLabel required id="demo-simple-select-standard-label">
                        Categoría
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        variant="standard"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        label="Categoría"
                        value={stepCategory}
                        onChange={e => setStepCategory(e.target.value)}
                      >
                        <MenuItem value="">
                          <em>Seleccionar una</em>
                        </MenuItem>
                        <MenuItem value={"Producción"}>Producción</MenuItem>
                        <MenuItem value={"Almacenamiento"}>Almacenamiento</MenuItem>
                        <MenuItem value={"Transporte"}>Transporte</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      required
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Nombre del proceso"
                      type="email"
                      fullWidth
                      variant="standard"
                      value={stepName}
                      onChange={e => setStepName(e.target.value)}
                    />
                    <TextField
                      id="datetime-local"
                      label="Fecha y hora del proceso"
                      type="datetime-local"
                      value={selectedDateTime}
                      onChange={handleDateTimeChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      variant="standard"
                      margin="dense"
                    />
                    {/* Version that uploads object _id and name
                    <FormControl fullWidth variant="standard">
                      <InputLabel required id="demo-simple-select-standard-label">
                        Lote
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        variant="standard"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        label="Lote"
                        value={stepBatch ? JSON.stringify(stepBatch) : ""}
                        onChange={e => setStepBatch(JSON.parse(e.target.value))}
                      >
                        <MenuItem value="">
                          <em>Seleccionar una</em>
                        </MenuItem>
                        {batches.map((item: any) =>
                          item.batchs.map((batch: any) => (
                            <MenuItem key={batch._id} value={JSON.stringify(batch)}>
                              {batch.name}
                            </MenuItem>
                          )),
                        )}
                      </Select>
                    </FormControl>*/}
                    {batches && batches.length > 0 ? (
                      <>
                        <FormControl fullWidth variant="standard">
                          <InputLabel required id="demo-simple-select-standard-label">
                            Lote
                          </InputLabel>

                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            variant="standard"
                            fullWidth
                            sx={{ marginTop: 2 }}
                            label="Lote"
                            value={stepBatch}
                            onChange={e => setStepBatch(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>Seleccione un lote</em>
                            </MenuItem>
                            {batches?.map((item: any) => (
                              <MenuItem key={item._id} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    ) : (
                      <>
                        <FormControl fullWidth variant="standard">
                          <InputLabel sx={{ color: "red" }}>No hay lotes disponibles*</InputLabel>
                        </FormControl>
                        <br />
                        <br />
                      </>
                    )}

                    {/*  */}
                    <TextField
                      required
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Descripción del proceso"
                      type="email"
                      fullWidth
                      variant="standard"
                      value={stepDescription}
                      onChange={e => setStepDescription(e.target.value)}
                    />
                    <TextField
                      required
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Realizador del proceso"
                      type="email"
                      fullWidth
                      variant="standard"
                      value={stepResponsible}
                      onChange={e => setStepResponsible(e.target.value)}
                    />
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <DialogTitle align="center">
                      Localización geográfica
                      <DialogContentText variant="caption">
                        Arraste el marcador para marcar ubicación
                      </DialogContentText>
                      <DialogContentText variant="caption">(2/3)</DialogContentText>
                    </DialogTitle>

                    {/*  @ts-expect-error */}
                    <Wrapper render={render} apiKey={process.env.REACT_APP_MAPS_API_KEY}>
                      <MapComponent
                        initialLat={markerPosition?.lat}
                        initialLng={markerPosition?.lng}
                        onMarkerDrag={handleMarkerDrag}
                      />
                    </Wrapper>
                  </>
                )}

                {activeStep === 2 && (
                  <>
                    <DialogTitle align="center">
                      Imagen
                      <DialogContentText variant="caption">(3/3) Opcional</DialogContentText>
                    </DialogTitle>

                    <div style={{ textAlign: "center" }}>
                      <label htmlFor="image-upload-input">
                        <IconButton component="span" sx={{ pl: 0 }}>
                          <PhotoCameraIcon sx={{ color: "#4CB766" }} fontSize="large" />
                        </IconButton>
                      </label>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        id="image-upload-input"
                      />
                      {imagePreview && (
                        <div>
                          <img src={imagePreview} alt="Preview" style={{ maxWidth: "100%" }} />
                        </div>
                      )}
                    </div>
                  </>
                )}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Atras
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Omitir
                    </Button>
                  )}
                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Finalizar" : "Continuar"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
