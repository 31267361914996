import { Magic } from "magic-sdk";
import { useContext, useState, useEffect, createContext } from "react";
import Web3 from "web3";
import useDataStore from "../../store";
import { useNavigate } from "react-router-dom";

const magic = new Magic("pk_live_58ACD3B526B765A7", {
  network: "mainnet",
  locale: "es",
});

export const AuthContext = createContext<any>({
  web3: undefined,
  handleConnect: async () => {},
  handleLogout: async () => {},
  account: undefined,
  isConnected: undefined,
  userToken: undefined,
  setUserToken: () => {},
  userPayload: undefined,
  setUserPayload: () => {},
});

export const AuthProvider = ({ children }: { children: any }) => {
  // const [account, setAccount] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  const [web3, setWeb3] = useState(new Web3());

  const [userToken, setUserToken] = useState<string | null>(null);
  const [userPayload, setUserPayload] = useState<any[] | null>(null);

  const { account, setAccount, setUserData } = useDataStore();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const web3 = new Web3(magic.rpcProvider as any);
      setWeb3(web3);
    }
  }, []);

  const handleConnect = async (email: string): Promise<boolean> => {
    try {
      // Request email login with Magic and send OTP
      await magic.auth.loginWithEmailOTP({ email });

      // Connect to the user's wallet with Magic
      const accounts = await magic.wallet.connectWithUI();

      // Set user account and update state
      setAccount(accounts[0]);
      setIsConnected(true);

      // Return true to indicate successful connection
      return true;
    } catch (error) {
      // Handle authentication errors
      console.error("Error logging in:", error);
      // You may want to provide feedback to the user here

      // Return false to indicate failed connection
      return false;
    }
  };

  const handleLogout = async () => {
    await magic.user.logout();
    setAccount(null);
    setUserData(null);
    setUserToken(null);
    setUserPayload(null);
    setIsConnected(false);
    window.location.href = "https://agrojusto.com.ar";
  };

  return (
    <AuthContext.Provider
      value={{
        web3,
        handleConnect,
        account,
        isConnected,
        handleLogout,
        userToken,
        setUserToken,
        userPayload,
        setUserPayload,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
