import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";

export const ProductTitle = styled(Typography)(() => ({
  fontWeight: "500",
  fontSize: "1.5rem",
}));

export const ProductName = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "1rem",
  color: "#797979",
  marginTop: "2%",
  marginBottom: "2%",
}));

export const ProductDescription = styled(Typography)(() => ({
  fontWeight: "400",
  fontSize: "1.3rem",
}));

export const DetailsBtn = styled(Button)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  color: "#FFFFFF",
  backgroundColor: "#689f38",
  border: "2px solid #679F38",
  borderRadius: "8px",
  textTransform: "none",
  width: "100%",
  marginBottom: "3%",
  "&:hover": {
    backgroundColor: "#689f38",
    color: "white",
  },
}));
