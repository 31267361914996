import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";

export const DashboardLayout = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "normal",
  gap: "2%",
  paddingLeft: "4%",
  paddingRight: "4%",
  paddingTop: "2%",
  backgroundColor: "#F6F7FB",
  height: "100vh",
  wordWrap: "break-word",
}));

export const Anchor = styled(Typography)(() => ({
  fontSize: "0.875rem",
  color: "#0029FF",
  cursor: "pointer",
}));

export const Title = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "1.5rem",
}));

export const Subtitle = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "1.3rem",
}));

export const Text = styled(Typography)(() => ({
  fontWeight: "400",
  fontSize: "1.3rem",
  color: "#141414",
}));

export const LogoutBtn = styled(Button)(() => ({
  fontWeight: "500",
  color: "#0029FF",
  background: "#FFFFFF",
  border: "2px solid #0029FF",
  borderRadius: "8px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
}));
