import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function NewBatchDialog({
  open,
  setOpen,
  handleCreateNewBatch,
  process,
  batches,
  newBatchName,
  setNewBatchName,
}: any) {
  //console.log(process);

  const [isCreating, setIsCreating] = React.useState<boolean>(false);
  const [batch, setBatch] = React.useState<string>("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateBatch = async () => {
    setIsCreating(true);
    await handleCreateNewBatch(batch);
    setIsCreating(false);
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Crear un nuevo lote de este producto</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Generarás un nuevo lote de este producto. Para esto deberás seleccionar un lote base e
            ingresar la fecha (o identificador) del nuevo lote.
            <br />
            Tené en cuenta que ese identificador debe poseer el mismo formato para todo el producto.
            <br />
          </DialogContentText>
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-standard-label">Lote base</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              variant="standard"
              fullWidth
              sx={{ marginTop: 2 }}
              label="Lote"
              value={batch}
              onChange={e => setBatch(e.target.value)}
            >
              <MenuItem value="">
                <em>Seleccionar una</em>
              </MenuItem>
              {batches.map((item: string, index: number) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Fecha del lote (DD/MM/AAAA) o identificador"
            type="email"
            fullWidth
            variant="standard"
            value={newBatchName}
            onChange={e => setNewBatchName(e.target.value)}
          />
        </DialogContent>
        {isCreating ? (
          <DialogActions>
            <DialogContentText>Generando nuevo lote...&nbsp;&nbsp;</DialogContentText>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleCreateBatch}>Generar nuevo lote</Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
