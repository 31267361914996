import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import useDataStore from "../../store";
import NavBar from "../../components/NavBar";
import * as Styled from "./styles";
import ArrowSvg from "../../assets/Arrow.svg";

const Profile = () => {
  const { handleLogout, account } = useAuth();
  const { userData } = useDataStore();
  let navigate = useNavigate();

  useEffect(() => {
    console.log("Profile...");
    console.log(userData);
  }, []);

  if (!userData) {
    window.location.href = "/onboard/user@email.com";
  }

  return (
    <>
      <NavBar />
      <Styled.DashboardLayout>
        <Styled.Anchor onClick={() => navigate("/")}>
          <img src={ArrowSvg} alt="Arrow" style={{ verticalAlign: "middle" }} />
          Volver a mis productos
        </Styled.Anchor>

        <Styled.Title>Mis datos</Styled.Title>
        <Styled.Subtitle>Email</Styled.Subtitle>
        <Styled.Text>{userData?.user?.email}</Styled.Text>
        <Styled.Subtitle>Address Blockchain</Styled.Subtitle>
        <Styled.Text>{account}</Styled.Text>

        <Styled.LogoutBtn onClick={() => handleLogout()}>Cerrar sesión</Styled.LogoutBtn>
      </Styled.DashboardLayout>
    </>
  );
};

export default Profile;
