import { styled } from "@mui/material/styles";

export const DashboardLayout = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "normal",
  gap: "2%",
  paddingLeft: "4%",
  paddingRight: "4%",
  paddingTop: "2%",
  backgroundColor: "#F6F7FB",
  height: "100vh",
  wordWrap: "break-word",
}));
