import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const ViewProductLayout = styled("div")(({ theme }) => ({
  paddingLeft: "4%",
  paddingRight: "4%",
  paddingTop: "2%",
  backgroundColor: "#F6F7FB",
  height: "100vh",
}));

export const Anchor = styled(Typography)(() => ({
  fontSize: "0.875rem",
  color: "#0029FF",
  cursor: "pointer",
}));

export const Title = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "1.5rem",
}));

export const Dot = styled("div")({
  width: 10,
  height: 10,
  borderRadius: "50%",
  backgroundColor: "green",
  opacity: 0.7,
  position: "relative",
  animation: "glowing 2s infinite",
  animationName: "glowing",
  "@keyframes glowing": {
    "0%, 100%": {
      backgroundColor: "green",
    },
    "50%": {
      backgroundColor: "white",
    },
  },
});
