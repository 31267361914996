import React, { useState, useEffect } from "react";
import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, SafeEventEmitterProvider, WALLET_ADAPTERS } from "@web3auth/base";
import RPC from "./web3RPC";
import { create as ipfsHttpClient } from "ipfs-http-client";
import Web3 from "web3";

import { Web3AuthNoModal } from "@web3auth/no-modal";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import NoModal from "./components/NoModal";
import { Toaster } from "react-hot-toast";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import OnboardUser from "./views/OnboardUser";
import { AuthContext, AuthProvider } from "./providers/auth";
import { ThemeProvider } from "@mui/material";
import theme from "./styles/theme";
import Dashboard from "./views/Dashboard";
import ViewProduct from "./views/ViewProduct";
import useDataStore from "./store";
import Transactions from "./views/Transactions";
import Process from "./views/Process";
import Profile from "./views/Profile";

const App = () => {
  const { userData } = useDataStore();

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Toaster />
        <Router>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/transacciones" element={<Transactions />} />
            <Route path="/product/:productId" element={<ViewProduct />} />
            <Route path="/onboard/:userEmail" element={<OnboardUser />} />
            <Route path="/process/:id" element={<Process />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
