import { createTheme } from "@mui/material";

export const breakpoints = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1240,
  xl: 1800,
};

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

export default theme;
