import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import * as Styled from "./styles";

export default function EachProductCard({ product }: any) {
  const navigate = useNavigate();

  return (
    <Card sx={{ minWidth: 200, maxWidth: 500, marginTop: "1em" }}>
      <CardContent>
        <Styled.ProductTitle>{product?.title}</Styled.ProductTitle>
        <Styled.ProductName>{product?.productType?.name}</Styled.ProductName>
        <Styled.ProductDescription variant="body2">
          {product?.description}
        </Styled.ProductDescription>
      </CardContent>
      <CardActions>
        <Styled.DetailsBtn onClick={() => navigate(`/product/${product._id}`)}>
          Ver Detalles
        </Styled.DetailsBtn>
      </CardActions>
    </Card>
  );
}
