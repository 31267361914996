import React, { useEffect, useState } from "react";
import { collection, query, orderBy, onSnapshot, limit } from "firebase/firestore";
import { db } from "../../firebase";
import NavBar from "../../components/NavBar";
import * as Styled from "./styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const Transactions = () => {
  const isDesktop = useMediaQuery("(min-width:600px)");
  const [transactions, setTransactions] = useState<any[]>([]);

  useEffect(() => {
    const unsub = onSnapshot(
      query(collection(db, "records"), orderBy("created", "desc"), limit(7)),
      docu => {
        // updateData();
        const data = docu.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        // const sortedData = data.sort(function (x: any, y: any) {
        //   return y.created - x.created;
        // });
        console.log("Current data: ", data);
        setTransactions(data);
      },
    );
  }, []);

  function parseDate(timestamp: any) {
    if (!timestamp.seconds) return "N/A";
    return new Date(+timestamp.seconds * 1000).toLocaleDateString("en-US");
  }

  function parseTime(timestamp: any) {
    if (!timestamp.seconds) return "N/A";
    return new Date(+timestamp.seconds * 1000).toLocaleTimeString("en-US");
  }

  return (
    <>
      <NavBar />
      <Styled.DashboardLayout>
        <Styled.Title>
          Transacciones (info on-chain) &nbsp; <Styled.Dot />
        </Styled.Title>

        <br />
        <br />
        <>
          <TableContainer
            sx={{
              overflowX: isDesktop ? "unset" : "scroll",
              "&::-webkit-scrollbar": {
                height: "12px", // Adjust scrollbar height
                width: "12px", // Adjust scrollbar width
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888", // Adjust scrollbar thumb color
                borderRadius: "6px", // Adjust scrollbar thumb border radius
              },
            }}
          >
            <Table sx={{ minWidth: "max-content" }}>
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      color: "#000000",
                      backgroundColor: "#E0E0E0",
                      borderBottom: "1px solid #000000",
                    },
                  }}
                >
                  <TableCell style={{ fontWeight: 800 }}>Proceso</TableCell>
                  <TableCell style={{ fontWeight: 800 }}>Categoría</TableCell>
                  <TableCell style={{ fontWeight: 800 }}>Productor</TableCell>
                  <TableCell style={{ fontWeight: 800 }}>Producto</TableCell>
                  <TableCell style={{ fontWeight: 800 }}>Fecha</TableCell>
                  <TableCell style={{ fontWeight: 800 }}>Hora</TableCell>
                  <TableCell style={{ fontWeight: 800 }}>Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map(row => (
                  <TableRow key={row.signed_data.step.step_name}>
                    <TableCell component="th" scope="row">
                      {row.signed_data.step.step_name}
                    </TableCell>
                    <TableCell>{row.signed_data.step.step_category ?? "N/A"}</TableCell>
                    <TableCell>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://my.agrojusto.com.ar/user/${row.user_data.user._id}`}
                      >
                        {row.user_data.user.profile.lastName}
                      </a>
                    </TableCell>
                    <TableCell>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${process.env.REACT_APP_STORE_URL}/auth/mercado/productos/${row.signed_data.product.product_id}`}
                      >
                        {row.signed_data.product.product_name}
                      </a>
                    </TableCell>
                    <TableCell>{parseDate(row.created)}</TableCell>
                    <TableCell>{parseTime(row.created)}</TableCell>
                    <TableCell>
                      <a
                        href={row.ipfs_link.replace(
                          "https://ipfs.io/ipfs/",
                          "https://agrojusto-testnet.infura-ipfs.io/ipfs/",
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        <div></div>
      </Styled.DashboardLayout>
    </>
  );
};

export default Transactions;
