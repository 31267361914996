import { useEffect, useRef, useState } from "react";

interface MapComponentProps {
  initialLat: number;
  initialLng: number;
  onMarkerDrag: (lat: number, lng: number) => void;
  radius?: number;
}

export default function MapComponent({
  initialLat,
  initialLng,
  onMarkerDrag,
  radius = 500,
}: MapComponentProps) {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);
  const [circle, setCircle] = useState<google.maps.Circle | null>(null);

  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapRef.current) return;

    const newMap = new window.google.maps.Map(mapRef.current, {
      center: { lat: initialLat, lng: initialLng },
      zoom: 15,
    });

    const newMarker = new window.google.maps.Marker({
      position: { lat: initialLat, lng: initialLng },
      map: newMap,
      draggable: true,
    });

    newMarker.addListener("dragend", () => handleMarkerDragEnd(newMarker));

    const newCircle = new window.google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map: newMap,
      center: { lat: initialLat, lng: initialLng },
      radius: radius,
    });

    setMap(newMap);
    setMarker(newMarker);
    setCircle(newCircle);

    return () => {
      // Clean up when component unmounts
      newMarker.setMap(null);
      newCircle.setMap(null);
    };
  }, [initialLat, initialLng]);

  const handleMarkerDragEnd = (draggedMarker: google.maps.Marker) => {
    const newPosition = draggedMarker.getPosition();
    if (newPosition) {
      const newLat = newPosition.lat();
      const newLng = newPosition.lng();
      onMarkerDrag(newLat, newLng);
      if (circle) {
        circle.setCenter(new window.google.maps.LatLng(newLat, newLng));
      }
    }
  };

  useEffect(() => {
    // Update marker position when initialLat or initialLng changes
    if (marker) {
      marker.setPosition(new window.google.maps.LatLng(initialLat, initialLng));
    }
  }, [marker, initialLat, initialLng]);

  return <div ref={mapRef} style={{ height: "100%", minHeight: "350px" }}></div>;
}
