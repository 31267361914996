import React, { useEffect, useState } from "react";
import useDataStore from "../../store";
import EachProductCard from "./EachProductCard";
import { CircularProgress } from "@mui/material";
import * as Styled from "./styles";

const UserProducts = () => {
  const { userData, userToken } = useDataStore();

  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<any[]>([]);

  useEffect(() => {
    console.log("Use Effect from UserProducts");
    fetchProductsList();
  }, []);

  const fetchProductsList = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/products/email/${userData?.user?.email}?itemsPerPage=999&page=1`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      },
    );

    const json = await res.json();
    setProducts(json.docs);
    setLoading(false);

    console.log("Productos: ", json.docs);
  };

  return (
    <div>
      <Styled.Title>Mis Productos</Styled.Title>
      <div style={{ marginTop: "1em" }}>
        {!loading && products.map((item, i) => <EachProductCard key={i} product={item} />)}
        {loading && (
          <div
            style={{
              width: "100%",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexWrap: "wrap",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProducts;
